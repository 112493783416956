<header>
  <nav class="navbar navbar-lg banner">
    <div class="container-lg mobile">
      <div class="logo lap">
        <img
          src="assets/images/img/ieelogowhite.webp"
          alt=""
          [routerLink]="['/view']"
        />
      </div>

      <!-- <marquee>
          Project Management & Investor Relationship Coming Soon.
        </marquee> -->
      <div class="logo lap sm">
        <img
          src="assets/images/img/ieelogowhite.webp"
          alt=""
          [routerLink]="['/view']"
        />
      </div>
      <!-- <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"> -->
      <!-- <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button> -->

      <div class="d-flex justify-content-between pt-1 nav-list-ff">
        <!-- <form class="form-inline my-2 my-lg-0">
          <app-search type="search"></app-search>
        </form> -->
        <div class="text-white me-2 point">
          <ul class="navbar-nav mx-auto">
            <div class="navbar-nav-inner">
              <li
                class="nav-item"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <h3
                  class="nav-link text-white cursor-hand"
                  [routerLink]="['/view']"
                >
                  Profiles
                </h3>
              </li>
              <li
                class="nav-item"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <h3
                  class="nav-link text-white cursor-hand"
                  [routerLink]="['/view/projects']"
                >
                  Projects
                </h3>
              </li>
              <li
                class="nav-item"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <h3
                  class="nav-link text-white cursor-hand"
                  [routerLink]="['/view/vendor']"
                >
                  Vendors
                </h3>
              </li>
              <li
                class="nav-item"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <h3
                  class="nav-link text-white cursor-hand"
                  [routerLink]="['/view/watchNow/list']"
                >
                  Watch Now
                </h3>
              </li>
              <li
                class="nav-item"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <h3
                  class="nav-link text-white cursor-hand"
                  [routerLink]="['/view/event']"
                >
                  Event
                </h3>
              </li>
              <li
                class="nav-item"
                routerLinkActive="active"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                <h3
                  class="nav-link text-white cursor-hand"
                  [routerLink]="['userProject']"
                >
                  My Projects
                </h3>
              </li>
            </div>
          </ul>
        </div>
        <div class="anim-i pt-2 pe-2">
          <h3>
            <i
              class="fa fa-regular fa-bell text-white"
              (click)="notificationPop()"
            >
            </i>
          </h3>
        </div>

        <!-- <div class="profile-pic-sm mx-2 d-flex align-items-center justify-content-center cursor-hand"
          (click)="viewProfile(userSession.id)">
          <img class="img-fluid rounded-circle" [src]="userSession?.pictureFileLocations" alt="profile pic"
            onerror="this.onerror=null;this.src='assets/images/img/profile-avatar-1.png';" />
        </div> -->
        <div class="dropdown">
          <div
            class="profile-pic-sm mx-2 cursor-hand dropdown-toggle"
            id="dropdownMenuButton1"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            type="button"
          >
            <img
              class="img-fluid rounded-circle"
              [src]="userSession?.pictureFileLocations"
              alt="profile pic"
              onerror="this.onerror=null;this.src='assets/images/img/profile-avatar-1.png';"
            />
          </div>
          <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
            <li>
              <a
                class="dropdown-item cursor-hand"
                (click)="viewProfile(userSession.id)"
                >View Profile</a
              >
            </li>
            <li>
              <a
                class="dropdown-item cursor-hand"
                (click)="viewInvestments()"
                >My Investments</a
              >
            </li>
            <li><a class="dropdown-item cursor-hand" (click)="signOut()">Sign Out</a></li>
          </ul>
        </div>
        <div class="menu-ico cursor-hand">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="800px"
            height="800px"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M4 18L20 18"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M4 12L20 12"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M4 6L20 6"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        </div>
      </div>
    </div>
  </nav>
</header>
