import { Component } from '@angular/core';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public isScrollbarVisible:Boolean=false
  constructor(
   
  ) {
   setInterval(() => {
    this.checkScrollbar(); 
   }, 1);
  }

  checkScrollbar = () => {
    this.isScrollbarVisible =
      document.documentElement.scrollHeight > window.innerHeight;
  };

}
