import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core';
import { ErrorService } from './error.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class CustomErrorHandlerService implements ErrorHandler {
  constructor(
    private injector: Injector,
    private errorService: ErrorService,
    private snackBar: MatSnackBar,
    private zone: NgZone,
    ) {}
    private errorLog: Map<string, number> = new Map();
  private readonly MAX_SAME_ERROR_COUNT = 3;
  private readonly ERROR_RESET_TIME = 5000; 


  handleError(error: unknown): void {

    const errorKey = this.getErrorKey(error);

    const currentCount = this.errorLog.get(errorKey) || 0;

    if (currentCount >= this.MAX_SAME_ERROR_COUNT) {
      // Stop logging repetitive errors
      console.error('Error suppressed due to repeated occurrence', error);
      return;
    }
    this.errorLog.set(errorKey, currentCount + 1);

    // Show error snackbar
    this.snackBar.open('Error was detected! We are working on it!', 'Close', {
      duration: 2000, 
      horizontalPosition: 'center',
      verticalPosition: 'top'
    });

    // Log the warning
    console.error('Handler caught an error', error);

    // Reset error count after a timeout
    setTimeout(() => {
      this.errorLog.delete(errorKey);
    }, this.ERROR_RESET_TIME);
  }

  private getErrorKey(error: unknown): string {
    // Create a unique identifier for the error
    if (error instanceof Error) {
      return `${error.name}:${error.message}`;
    }
    
    // For non-Error objects, use a string representation
    return JSON.stringify(error);
  }

    // Store the error in the error service
    // this.errorService.addError(error);

    // Display a custom error message
    // const customMessage = this.getCustomErrorMessage(error);
    // console.warn('Handler caught an error', customMessage);
    // this.zone.run(() =>{
    //   this.snackBar.open('Error was detected! We are working on it!', 'Close', {
    //     duration: 2000,
    //     horizontalPosition: 'center',
    //     verticalPosition: 'top',
    //     // panelClass: ['error-snackbar']
    //   });
    // })
    // console.warn('Handler caught an error', error);
    // setTimeout(() => {
    // }, 60000);
  // }

  private getCustomErrorMessage(error: any) {
    // Define custom error messages based on error type or code
    if (error instanceof HttpErrorResponse) {
      return error.error;
    }
    // switch (error.status) {
    //   case 401:
    //     return 'Unauthorized access. Please log in.';
    //   case 404:
    //     return 'Resource not found.';
    //   case 500:
    //     return 'Internal server error. Please try again later.';
    //   default:
    //     return 'An unexpected error occurred.';
    // }
    // return 'An error occurred: ' + error.message;
    //   switch (error.code) {
    //     case 'NG0200':
    //       return 'Circular dependency in DI detected.';
    //     case 'NG0201':
    //       return 'No provider for the specified token.';
    //     case 'NG0300':
    //       return 'Template parse errors.';
    //     case 'NG0303':
    //       return 'Expression has changed after it was checked.';
    //     case 'NG0304':
    //       return 'Cannot read property of undefined in the template.';
    //     case 'NG0400':
    //       return 'FormControlName must be used with a parent FormGroup.';
    //     case 'NG0401':
    //       return 'No value accessor for the form control.';
    //     case 'NG0500':
    //       return 'Cannot match any routes for the provided URL segment.';
    //     case 'NG0501':
    //       return 'Router outlet is not activated.';
    //     case 'NG0600':
    //       return 'Injector has already been destroyed.';
    //     case 'NG0601':
    //       return 'Cannot assign to a read-only property.';
    //     default:
    //       return 'An unexpected error occurred.';
    //   }
  }
}
