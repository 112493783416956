<div class="authentication-wrap">
  <div class="logo">
    <img src="assets/images/img/ieelogowhite.webp" alt="logo" />
  </div>
  <div class="row">
    <div class="col-md-6 col-sm-12">
      <div class="login-img position-relative">
        <img
          class="img-fluid"
          src="assets/images/img/cameraSide-view.jpg"
          alt=""
        />
      </div>
    </div>
    <div class="offset-md-1 col-md-4 offset-md-1 col-sm-12 login-rhs">
      <div class="login h-100">
        <div
          class="h-100 d-flex flex-column align-items-center justify-content-center"
        >
          <div class="login-form w-100 p-2 pb-2" *ngIf="loginAccount">
            <h1 class="title2-highlighted text-center mb-3">Login</h1>
            <div class="">
              <form [formGroup]="loginForm" (ngSubmit)="onLoginSubmit()">
                <div class="mb-2">
                  <label for="" class="title-normal">Phone Number</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="phoneNumber"
                    placeholder="Phone Number"
                  />
                  <span
                    *ngIf="
                      loginForm.invalid &&
                      (loginForm.dirty || loginForm.touched)
                    "
                    class="text-danger"
                  >
                    <span *ngIf="loginForm.errors">This field is required</span>
                  </span>
                </div>
                <div class="mb-2">
                  <label for="" class="title-normal">Password</label>
                  <input
                    class="form-control"
                    type="password"
                    formControlName="password"
                    placeholder="Password"
                  />
                </div>
                <div class="mb-3 mt-4">
                  <p
                    class="title6-dim cursor-hand text-decoration-underline text-center"
                    (click)="accountChange()"
                  >
                    Don't have an account yet
                  </p>
                </div>
                <div class="mb-2 cursor-hand">
                  <div class="d-flex justify-content-center">
                    <button
                      type="submit"
                      class="btn w-100 btn-grad-ff btn-lg"
                      [disabled]="loginForm.invalid"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div class="login-form w-100 p-2 pb-2" *ngIf="signinAccount">
            <h1 class="title2-highlighted text-center mb-3">Sign Up</h1>
            <div class="">
              <form [formGroup]="signUpForm" (ngSubmit)="onSignUpSubmit()">
                <div class="row">
                  <div class="col-md-6 mb-2">
                    <label for="" class="title-normal">First Name</label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="firstName"
                      placeholder="First Name"
                    />
                  </div>
                  <div class="col-md-6 mb-2">
                    <label for="" class="title-normal">Last Name</label>
                    <input
                      class="form-control"
                      type="text"
                      formControlName="lastName"
                      placeholder="Last Name"
                    />
                  </div>
                </div>
                <div class="mb-2">
                  <label for="" class="title-normal">Email</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="email"
                    placeholder="Email"
                  />
                  <small
                    class="text-danger"
                    *ngIf="signUpForm.get('email')?.errors?.['customError']"
                  >
                    {{ signUpForm.get('email')?.errors?.['customError'] }}
                  </small>
                </div>
                <div class="mb-2">
                  <label for="" class="title-normal">Phone Number</label>
                  <input
                    class="form-control"
                    type="text"
                    formControlName="phoneNumber"
                    placeholder="Phone Number"
                  />
                  <small
                    class="text-danger"
                    *ngIf="signUpForm.get('phoneNumber')?.errors?.['customError']"
                  >
                    {{ signUpForm.get('phoneNumber')?.errors?.['customError'] }}
                  </small>
                </div>
                <div class="mb-2">
                  <label
                    for=""
                    class="title-normal tooltip-container"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="*Must be at least 8 characters long, including at least one number"
                  >
                    Password*
                  </label>
                  <input
                    class="form-control"
                    type="password"
                    formControlName="password"
                    placeholder="Password"
                  />

                  <small
                    class="text-danger"
                    *ngIf="signUpForm.get('password')?.errors?.['customError']"
                  >
                    {{ passwordErrorMessage }}
                  </small>
                </div>

                <div class="mb-3">
                  <label for="" class="title-normal">Confirm Password</label>
                  <input
                    class="form-control"
                    type="password"
                    formControlName="confirmPassword"
                    placeholder="Confirm Password"
                  />
                </div>
                <div class="mb-2 mt-3">
                  <div class="d-flex justify-content-center">
                    <p
                      class="title6-dim cursor-hand text-decoration-underline text-center"
                      (click)="accountChange()"
                    >
                      Login Account
                    </p>
                  </div>
                </div>
                <div class="mb-2 cursor-hand">
                  <div class="d-flex justify-content-center">
                    <button
                      class="btn w-100 btn-grad-ff btn-lg"
                      type="submit"
                      [disabled]="signUpForm.invalid"
                    >
                      Signup
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div
            class="d-flex justify-content-center pb-5 w-100"
            *ngIf="isGoogleLoginValid"
          >
            <div id="buttonDiv"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>