function getBeUrl() {
  let domainName = location.origin;
  let updatedDomainName = domainName.replace(/\bfe\b/, 'be');
  if (!updatedDomainName.endsWith('/')) {
    updatedDomainName += '/';
  }
  return updatedDomainName;
}

export const environment = {
  production: false,
  version: '1.0.0',
  baseUrl: getBeUrl(),
  googleTagManager: 'G-Y20F8EC8DY',
  googleClientId:
    '741222631577-9el4fvpu9rk426gc4acr4i4vbuslmc51.apps.googleusercontent.com',

  cookieDomain: 'http://127.0.0.1:8080/',
  apiVersion: 'api/v1/',
};
