import { STRING_TYPE } from '@angular/compiler';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imagesource',
  pure: true,
})
export class ImagesourcePipe implements PipeTransform {
  private defaultImages: any = {
    profile: 'assets/images/img/avatar.jpg',
    project: 'assets/images/img/film.png',
    event: 'assets/images/img/film.png',
    film: 'assets/images/img/film.png',
  };
  count = 1;
  transform(
    input: any[] | string | null | undefined,
    type: 'profile' | 'project' | 'event' | 'film' = 'film'
  ): string {
    const defaultImage = this.defaultImages[type] || this.defaultImages.film;

    if (type === 'profile') {
      return input || defaultImage;
    }

    if (type === 'event') {

      return input || defaultImage;
    }

    if (type === 'project') {
      if (Array.isArray(input) && input.length > 0) {
        return input[0];
      }
      if (typeof input === 'string') {
        return input || defaultImage;
      }

      return defaultImage;
    }

    return defaultImage;
  }
}
