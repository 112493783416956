<div class="password-change-container">
  <div class="otpForm form-floating">
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <h2 class="text-white content">Enter Your New Password</h2>

      <div class="form-group">
        <label for="newPassword" class="text-white">New Password:</label>
        <div class="password-field">
          <input
            id="newPassword"
            [type]="showNewPassword ? 'text' : 'password'"
            formControlName="newPassword"
            placeholder="Enter new password"
          />
          <i
            (click)="toggleNewPasswordVisibility()"
            class="fa"
            [ngClass]="showNewPassword ? 'fa-eye-slash' : 'fa-eye'"
          ></i>
        </div>
      </div>
      <div class="form-group">
        <label for="confirmPassword" class="text-white"
          >Confirm Password:</label
        >
        <div class="password-field">
          <input
            id="confirmPassword"
            [type]="showConfirmPassword ? 'text' : 'password'"
            formControlName="confirmPassword"
            placeholder="Confirm password"
          />
          <i
            (click)="toggleConfirmPasswordVisibility()"
            class="fa"
            [ngClass]="showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'"
          ></i>
        </div>
      </div>

      <div *ngIf="passwordMismatch" class="error text-white">
        Passwords do not match!
      </div>
      <button
        type="submit"
        [disabled]="passwordForm.invalid || passwordMismatch"
      >
        Reset Password
      </button>
    </form>
  </div>
</div>
