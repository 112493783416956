import { Component } from '@angular/core';
import { GoogleTagService } from './core/service/google-tag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Film Finance';
  constructor(private analytics: GoogleTagService){}
}
