import { Component } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-project-watch-list',
  templateUrl: './project-watch-list.component.html',
  styleUrls: ['./project-watch-list.component.scss'],
})
export class ProjectWatchListComponent {
  watchlistData: any;
  activeVideo: string | null = null;

  constructor(private apihelper: ApiHelper) {}

  ngOnInit() {
    this.getWatchList();
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  getWatchList() {
    this.apihelper
      .post({}, ApiEndPoints.watchListFind)
      .subscribe((response) => {
        this.watchlistData = response.data;
      });
  }
  onPlayerReady() {}
  onStateChange(event: any) {
    this.activeVideo = event;
  }
}
