import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {
  private errors: any[] = [];

  addError(error: any): void {
    this.errors.push(error);
  }

  getErrors(): any[] {
    return this.errors;
  }

  clearErrors(): void {
    this.errors = [];
  }
}
