import { Component, OnInit } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import formatTitle from 'src/app/core/helper/title-formatter.helper';


@Component({
  selector: 'app-vendor-list',
  templateUrl: './vendor-list.component.html',
  styleUrls: ['./vendor-list.component.scss']
})

export class VendorListComponent implements OnInit{
  formatTitle=formatTitle
  organisations: any;
  constructor(
    private apiHelper: ApiHelper,
  ) {}

  ngOnInit(): void {
    this.getVendors()
  }
  getVendors() {
    this.apiHelper.post({}, ApiEndPoints.organisationGet).subscribe((response) => {
      this.organisations = response.data
    })
  }
}


