import { Component, Pipe, PipeTransform } from '@angular/core';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';
import formatTitle from 'src/app/core/helper/title-formatter.helper';

@Component({
  selector: 'app-project-list',
  templateUrl: './project-list.component.html',
  styleUrls: ['./project-list.component.scss'],
})
export class ProjectListComponent {
  projects: any;
  searchQuery: any = {
    freeText: '',
    movieType: '',
    asset: '',
  };

  isChecked: boolean = false;

  constructor(private apiHelper: ApiHelper) {}
  formatTitle = formatTitle;
  ngOnInit(): void {
    this.getProjects(this.searchQuery);
  }

  getProjects(criteria: any) {
    this.apiHelper
      .post(
        {
          filters: criteria,
        },
        ApiEndPoints.projectSearch,
        true
      )
      .subscribe((response) => {
        this.projects = response.data.profileDetails;
      });
  }

  onSearchActive(searchFilter: any, event: any) {
    if (searchFilter === 'asset') {
      this.searchQuery[searchFilter] = '';
      this.isChecked = event.target.checked;
      if (event.target.checked) {
        this.searchQuery[searchFilter] = 'true';
      }
    } else {
      this.searchQuery[searchFilter] = event.target.value;
    }

    this.getProjects(this.searchQuery);
  }

  onButtonSwitch(searchFilter: any, value: any) {
    this.isChecked = !this.isChecked;

    this.searchQuery[searchFilter] = value;
    this.getProjects(this.searchQuery);
  }

  onCancel(checkedSelect: any, searchSelect : any) {
    checkedSelect.value = '';
searchSelect .value = ''
    this.searchQuery = {
      freeText: '',
      role: '',
      location: '',
    };
    this.getProjects(this.searchQuery);
  }
}
