<div class="container-lg pad-t">
  <div class="border mb-3 px-3 py-4 d-none">
    <div class="title1-highlighted">title1-highlighted</div>
    <h1 class="title1-highlighted">title1-highlighted</h1>
    <span class="title1-gradient">title1-gradient</span>
    <div class="title2-highlighted">title2-highlighted</div>
    <h2 class="title2-highlighted">title2-highlighted</h2>
    <div class="title3-highlighted">title3-highlighted</div>
    <h3 class="title3-highlighted">title3-highlighted</h3>
    <div class="title4-highlighted">title4-highlighted</div>
    <h4 class="title4-highlighted">title4-highlighted</h4>
    <div class="title4-dim">title4-dim</div>
    <h4 class="title4-dim">title4-dim</h4>
    <div class="title5-highlighted">title5-highlighted</div>
    <h5 class="title5-highlighted">title5-highlighted</h5>
    <div class="title5-dim">title5-dim</div>
    <h5 class="title5-dim">title5-dim</h5>
    <div class="title6-highlighted">title6-highlighted</div>
    <h6 class="title6-highlighted">title6-highlighted</h6>
    <div class="title6-dim">title6-dim</div>
    <h6 class="title6-dim">title6-dim</h6>
    <div class="title-normal">title-normal</div>
    <div class="title-normal-dim">title-normal-dim</div>
    <a href="#" class="hyper-link">hyper-link</a>
  </div>
  <!-- profile -->
  <div class="card bg-app-secondary2 p-4">
    <div class="profileDetails p-1">
      <div class="row mb-3">
        <div class="d-contents">
          <div class="profile-pic me-3">
            <img [src]="
                userDetails?.pictureFileLocations ||
                'assets/images/img/profile-avatar-1.png'
              " (click)="viewProfilePic(userDetails.pictureFileLocations)" class="cursor-hand" alt="IMAGE" />
            <!-- <img
                [src]="
                  userDetails?.pictureFileLocations ||
                  'assets/images/img/profile-avatar-1.png'
                "
                (click)="viewProfilePic(userDetails.pictureFileLocations)"
                class="cursor-hand img img-fluid"
                alt="IMAGE"
              /> -->
            <div class="spinner-border text-primary" role="status" *ngIf="spinner"></div>
            <input type="file" id="BtnBrowseHidden" name="files" class="d-none" (change)="updateProfilePic($event)" />
            <div class="col text-end cursor-hand" *ngIf="canEdit">
              <span class="edit-image profile-pic-action" *ngIf="userDetails.pictureFileLocations">
                <button class="btn btn-sm w-auto" mat-button [matMenuTriggerFor]="menu"><i
                    class="fa fa-edit"></i></button>
                <mat-menu #menu="matMenu" class="input-field form-control bg-app-primary">
                  <button mat-menu-item>
                    <label for="BtnBrowseHidden" id="LblBrowse" class="text-white cursor-hand">Change Image
                    </label>
                  </button>
                  <button mat-menu-item class="text-white" (click)="removeImage()">
                    Remove Image
                  </button>
                </mat-menu>
              </span>
              <span class="edit-image profile-pic-action" *ngIf="!userDetails.pictureFileLocations">
                <button class="btn btn-sm w-auto" mat-button [matMenuTriggerFor]="menu"><i
                    class="fa fa-edit"></i></button>
                <mat-menu #menu="matMenu" class="input-field form-control bg-app-primary">
                  <button mat-menu-item>
                    <label for="BtnBrowseHidden" id="Lbl1Browse" class="text-white cursor-hand">Add image
                    </label>
                  </button>
                </mat-menu>
              </span>
            </div>
          </div>
        </div>
        <div class="col">
          <div *ngIf="!profileEdit">
            <div class="row pe-3 ps-3">
              <div class="col-md-12">
                <div class="mb-3 position-relative">
                  <div class="title2-highlighted">{{ userDetails.name }}</div>
                  <div class="action-icon" *ngIf="canEdit">
                    <div mat-menu-item class="icon-btn cursor-hand" (click)="onProfileToggle(profileEdit)">
                      <i class="fa fa-edit"></i>
                    </div>
                    <!-- <div class="anim-i" *ngIf="canEdit">
                      <h3>
                        <span mat-button [matMenuTriggerFor]="menu">
                          <i class="fa fa-edit"></i>
                          <mat-menu #menu="matMenu" class="input-field form-control bg-app-primary">
                            <button mat-menu-item class="icon-btn" (click)="signOut()">
                              <i class="fa fa-sign-out-alt" aria-hidden="true"></i>
                            </button>
                          </mat-menu>
                        </span>
                      </h3>
                    </div> -->
                  </div>
                </div>
                <div class="row text-white">
                  <div class="col-md-12">
                    <h6 class="title6-highlighted" *ngIf="userDetails?.industryRole?.length !== 0">
                      <img src="assets/images/svg/theaters.svg" class="me-2" />{{ userDetails.industryRole }}
                    </h6>
                    <h6 class="title6-highlighted" *ngIf="
                        userDetails?.location?.city ||
                        userDetails.location?.country
                      ">
                      <img src="assets/images/svg/location_on.svg" class="me-2" />{{
                      dataFormator.locationArray(
                      userDetails?.location?.city,
                      userDetails.location?.country
                      )
                      }}
                    </h6>
                    <div *ngIf="visibility('email')">
                      <div class="position-relative" *ngIf="userDetails.email">
                        <h6 class="title6-highlighted">
                          <img class="me-2" src="assets/images/svg/mail2.svg" />{{ userDetails.email }}
                        </h6>
                        <div class="view-toggle anim-i" *ngIf="canEdit" (click)="toggle('email')">
                          <i *ngIf="hide" class="fa fa-solid fa-eye me-2"></i>
                          <i *ngIf="!hide" class="fa fa-solid fa-eye-slash pe-2"></i>
                        </div>
                      </div>
                    </div>
                    <h6 class="title6-highlighted">
                      {{ userDetails.languages }}
                    </h6>
                  </div>
                </div>
                  <h5 class="title5-dim tooltip-ff">
                    {{ userDetails.bio | trimText:700 }}
                    <div *ngIf="formatTitle(userDetails.bio,700)" class="tooltiptext-ff">{{formatTitle(userDetails.bio,700)}}</div>
                  </h5>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12 form-details">
              <div class="" *ngIf="profileEdit">
                <form [formGroup]="updateProfile">
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 col-sm-12 form-details mt-3">
                        <div class="mb-3">
                          <div class="text-white">First Name</div>
                          <input type="text" class="input-field form-control" formControlName="firstName" />
                        </div>
                        <div class="mb-3">
                          <div class="text-white">Last Name</div>
                          <input type="text" class="input-field form-control" formControlName="lastName" />
                        </div>
                        <div class="text-white">Role:</div>
                        <div class="mb-3">
                          <select class="input-field form-select" id="rolesSelect" formControlName="industryRole"
                            (change)="onItemSelect($event, 'industryRole')">
                            <option *ngFor="let role of roleLists" [value]="role"
                              [disabled]="isItemSelected(role, 'industryRole')"
                              (keydown)="handleKeydown($event, 'industryRole')">
                              {{ role }}
                            </option>
                          </select>
                        </div>

                        <!-- Displaying selected roles as badges -->
                        <div class="selected-roles mt-2">
                          <span *ngFor="
                              let role of config['industryRole']['selectArray']
                            " class="badge bg-primary me-2">
                            {{ role }}
                            <button type="button" class="btn-close btn-close-white ms-2" aria-label="Close"
                              (click)="removeItem(role, 'industryRole')"></button>
                          </span>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12 form-details">
                        <div formGroupName="location">
                          <div class="mb-3 mt-3">
                            <div class="text-white">City</div>
                            <input type="text" class="input-field form-control" formControlName="city" />
                          </div>
                          <div class="mb-3">
                            <div class="text-white">Country</div>
                            <input type="text" class="input-field form-control" formControlName="country" />
                          </div>
                        </div>
                        <!-- Language Selection Dropdown -->
                        <div class="text-white">Languages:</div>
                        <div class="mb-3">
                          <select class="input-field form-control" id="languagesSelect" formControlName="languages"
                            (change)="onItemSelect($event, 'languages')">
                            <option *ngFor="let language of languages" [value]="language"
                              [disabled]="isItemSelected(language, 'languages')">
                              {{ language }}
                            </option>
                          </select>
                        </div>

                        <!-- Displaying selected languages as badges -->
                        <div class="selected-languages mt-2">
                          <span *ngFor="
                              let language of config['languages']['selectArray']
                            " class="badge bg-success me-2">
                            {{ language }}
                            <button type="button" class="btn-close btn-close-white ms-2" aria-label="Close"
                              (click)="removeItem(language, 'languages')"></button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="mb-3">
                    <div class="text-white">Email</div>
                    <input type="text" class="input-field form-control" formControlName="email" />
                  </div>

                  <div class="mb-2">
                    <div class="text-white">Bio</div>
                    <textarea name="" class="input-field form-control" formControlName="bio"></textarea>
                  </div>

                  <div class="d-flex pt-2 text-center justify-content-end">
                    <div>
                      <button type="button" id="abutton" class="btn btn-grad-ff m-1" (click)="onSave()">
                        Save
                      </button>
                    </div>
                    <div>
                      <button type="button" class="btn btn-secondary-outline m-1"
                        (click)="onProfileToggle(profileEdit)">
                        Cancel
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- projects -->
      <div class="row mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <span class="title4-highlighted">Projects</span>
          <div>
            <button class="btn btn-grad-ff btn-w-md" (click)="onEditModalClick({}, '#projectEdit', 'add')"
              *ngIf="canEdit">
              <i class="fa fa-solid fa-plus"></i>Add Project
            </button>
          </div>
        </div>
      </div>
      <!-- projectView -->
      <div class="row mb-3">
        <div class="col-lg-3 col-md-4 col-sm-12 mb-2" *ngFor="let course of recordInfo['project']; let i = index">
          <div class="card bg-app-secondary2 point rounded position-relative project-card"
            *ngIf="course.pictureFileLocation">
            <img [src]="course?.pictureFileLocation" class="img-project" alt="" />
            <div class="action-icon text-end cursor-hand" *ngIf="canEdit">
              <i class="fa fa-edit icon-btn" (click)="onEditModalClick(course, '#projectEdit', 'edit')"></i>
              <i class="fa fa-trash icon-btn" (click)="onDeleteModal(course)"></i>
            </div>
            <!-- (click)="onDeleteClick(course.id, 'project') -->
            <div class="box-detail-show p-3">
              <div class="row">
                <div class="col-lg-12">
                  <div class="me-4 pe-1">
                    <h6 class="title4-highlighted tooltip-ff">
                      {{ course.string1 | trimText:25 }}
                      <span *ngIf="formatTitle(course.string1,25)"
                        class="tooltiptext-ff">{{formatTitle(course.string1,25)}}</span>
                    </h6>
                  </div>
                  <h6 class="title6-dim">
                    {{ dataFormator.dateFormat(course.date1, course.date2) }}
                  </h6>
                  <h6 class="title6-highlighted">
                    {{ course.string2 }}
                  </h6>
                  <!-- <div class="text-height">
                  </div> -->
                  <h6 class="title6-highlighted tooltip-ff">
                    {{ course.text1 | trimText:200 }}
                    <span *ngIf="formatTitle(course.text1,200)"
                      class="tooltiptext-ff">{{formatTitle(course.text1,200)}}</span>
                  </h6>
                  <h6 class="view-project cursor-hand" *ngIf="course.string4">
                    <a (click)="handleLink(course.string4)" target="_blank" class="text-white text-decoration-underline"
                      rel="noreferrer noopener">View Projects</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div class="card bg-app-secondary2 point rounded position-relative" *ngIf="!course.pictureFileLocation">
            <div class="action-icon text-end cursor-hand" *ngIf="canEdit">
              <i class="fa fa-edit icon-btn" (click)="onEditModalClick(course, '#projectEdit', 'edit')"></i>
              <i class="fa fa-trash icon-btn" (click)="onDeleteModal(course)"></i>
            </div>
            <!-- (click)="onDeleteClick(course.id, 'project') -->
            <div class="box-detail-show p-3">
              <div class="row">
                <div class="col-lg-12">
                  <div class="me-4 pe-1">
                    <h6 class="title4-highlighted tooltip-ff">
                      {{ course.string1 | trimText:25 }}
                      <span *ngIf="formatTitle(course.string1,25)"
                        class="tooltiptext-ff">{{formatTitle(course.string1,25)}}</span>
                    </h6>
                    <!-- <h5 class="title5-dim tooltip-ff">
                    {{ userDetails.bio | trimText:700 }}
                    <span *ngIf="formatTitle(userDetails.bio,700)" class="tooltiptext-ff">{{formatTitle(userDetails.bio,700)}}</span>
                  </h5> -->
                  </div>
                  <h6 class="title6-dim">
                    {{ dataFormator.dateFormat(course.date1, course.date2) }}
                  </h6>
                  <h6 class="title6-highlighted">
                    {{ course.string2 }}
                  </h6>
                  <h6 class="title6-highlighted tooltip-ff">
                    {{ course.text1 | trimText:200 }}
                    <span *ngIf="formatTitle(course.text1,200)"
                      class="tooltiptext-ff">{{formatTitle(course.text1,200)}}</span>
                  </h6>
                  <h6 class="view-project cursor-hand" *ngIf="course.string4">
                    <a (click)="handleLink(course.string4)" class="text-white text-decoration-underline" rel="noreferrer noopener" target="_blank">View Projects</a>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- ACHIEVEMENTS -->
      <div class="row mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <span class="title4-highlighted">Achievements & Recognitions</span>
          <div>
            <button class="btn btn-grad-ff btn-w-md" (click)="onEditModalClick({}, '#achievementForm', 'add')"
              *ngIf="canEdit">
              <i class="fa fa-solid fa-plus"></i>Add Award
            </button>
          </div>
        </div>
      </div>

      <!-- Achive view -->
      <div class="row mb-3">
        <div class="col-lg-3 col-md-4 col-sm-12 mb-2" *ngFor="let items of recordInfo['achievement']">
          <div class="card bg-app-secondary2 point rounded p-3">
            <div class="action-icon text-end cursor-hand" *ngIf="canEdit">
              <i class="fa fa-edit icon-btn" (click)="onEditModalClick(items, '#achievementForm', 'edit')"></i>
              <i class="fa fa-trash icon-btn" (click)="onDeleteModal(items)"></i>
            </div>
            <div class="row">
              <div class="col-md-12">
                <h6 class="title6-dim">Award Name</h6>
                <h6 class="title6-highlighted">{{ items.string1 }}</h6>
                <h6 class="title6-dim">Awarded For</h6>
                <h6 class="title6-highlighted">
                  {{ items.string2 }}
                </h6>
                <h6 class="title6-dim">Date</h6>
                <h6 class="title6-highlighted">
                  {{ dataFormator.dateFormat(items.date1, null) }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Networks -->
      <div class="row mb-3">
        <div class="d-flex justify-content-between align-items-center">
          <span class="title4-highlighted">Networks & Associations</span>
          <div>
            <button class="btn btn-grad-ff btn-w-md" (click)="onEditModalClick({}, '#networkForm', 'add')"
              *ngIf="canEdit">
              <i class="fa fa-solid fa-plus"></i>Add Network
            </button>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-3 col-md-4 col-sm-12" *ngFor="let items of recordInfo['network']">
          <div class="card-wrap bg-app-secondary2 point rounded mb-2 position-relative">
            <div class="action-icon text-end cursor-hand" *ngIf="canEdit">
              <i class="fa fa-edit icon-btn" (click)="onEditModalClick(items, '#networkForm', 'edit')"></i>
              <i class="fa fa-trash icon-btn" (click)="onDeleteModal(items)"></i>
            </div>
            <div class="row p-3">
              <div>
                <div class="me-4 pe-1">
                  <h6 class="title6-dim">Networks</h6>
                </div>
                <h6 class="title6-highlighted">
                  {{ items.string1 }}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- recommendation2 -->
      <div class="row mb-2">
        <div class="d-flex justify-content-between align-items-center">
          <span class="title4-highlighted">Recommendations</span>
          <div style="min-height: 38px">
            <button class="btn btn-grad-ff btn-w-md" (click)="onEditModalClick({}, '#recommendationForm', 'add')"
              *ngIf="canEdit === false">
              <i class="fa fa-solid fa-plus me-2"></i>Add Recommendation
            </button>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-lg-4 col-md-4 col-sm-12" *ngFor="let items of recordInfo['recommendation']">
          <div class="card-wrap bg-app-secondary2 rounded d-flex">
            <div class="profile-team d-flex align-items-center">
              <img src="{{ items.json1?.pictureFileLocations }}" alt="user"
                onerror="this.src ='assets/images/img/profile-avatar-1.png'" />
            </div>
            <div class="col py-2">
              <div class="position-relative pe-3">
                <div class="action-icon-t2 cursor-hand text-end mb-2" *ngIf="!canEdit && profileId === items.json1.id">
                  <i class="fa fa-edit icon-btn" (click)="
                      onEditModalClick(items, '#recommendationForm', 'edit')
                    "></i>
                  <i class="fa fa-trash icon-btn" (click)="onDeleteModal(items)"></i>
                </div>
                <div *ngIf="!canEdit && profileId === items.json1.id">
                  <h6 class="title6-highlighted">{{ items.json1?.name }}</h6>
                  <h6 class="title-normal tooltip-ff">
                    {{ items.text1 | trimText:300 }}
                    <span *ngIf="formatTitle(items.text1,300)"
                      class="tooltiptext-ff tooltip-top">{{formatTitle(items.text1,300)}}</span>
                  </h6>
                </div>
              </div>
              <div *ngIf="canEdit && profileId === items.json1.id">
                <h4 class="title6-highlighted">{{ items.json1?.name }}</h4>
                <h6 class="title-normal tooltip-ff">
                  {{ items.text1 | trimText:300 }}
                  <span *ngIf="formatTitle(items.text1,300)"
                    class="tooltiptext-ff tooltip-top">{{formatTitle(items.text1,300)}}</span>
                </h6>
              </div>
              <div *ngIf="profileId !== items.json1.id">
                <h6 class="title6-highlighted">{{ items.json1?.name }}</h6>
                <h6 class="title-normal tooltip-ff">
                  {{ items.text1 | trimText:300 }}
                  <span *ngIf="formatTitle(items.text1,300)" class="tooltiptext-ff tooltip-top">{{
                    formatTitle(items.text1,300) }}</span>
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal fade" id="projectEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-app-primary">
      <div class="modal-body p-4">
        <form [formGroup]="addForm">
          <div class="mb-3">
            <h6 class="title6-highlighted">Project Name:</h6>
            <input type="text" class="input-field form-control" formControlName="string1" />
          </div>
          <div class="mb-3">
            <h6 class="title6-highlighted">From :</h6>
            <input type="month" class="input-field form-control" formControlName="date1"
              (input)="handleInput($event , 'date1')" />
            <h6 class="title6-highlighted">To :</h6>
            <input type="month" class="input-field form-control" (input)="handleInput($event , 'date2')"
              formControlName="date2" />
          </div>
          <div class="mb-3">
            <h6 class="title6-highlighted">Role:</h6>
            <select class="input-field form-control" aria-label="Default select example" formControlName="string2">
              <option value="" disabled selected>
                Select your option
              </option>
              <option *ngFor="let role of roleLists" [value]="role">
                {{ role }}
              </option>
            </select>
          </div>
          <div class="mb-3">
            <h6 class="title6-highlighted">Link to Project(optional)</h6>
            <input type="text" class="input-field form-control" formControlName="string4" />
          </div>
          <div class="mb-3">
            <h6 class="title6-highlighted">Details:</h6>
            <textarea name="" class="input-field form-control" id="" formControlName="text1"></textarea>
          </div>
          <div class="mb-1 text-end">
            <div class="btn btn-grad-ff ms-2" (click)="onEditModalSubmit('project' , '#projectEdit' )">
              <span>Save</span>
            </div>
            <div class="btn btn-secondary-outline ms-2" (click)="onModalCancel('#projectEdit')">
              <span>Cancel</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div> -->

<div class="modal fade" id="projectEdit" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content bg-app-primary">
      <div class="modal-body">
        <form [formGroup]="addForm">
          <div class="row mb-2">
            <div class="col-md-4 mb-2">
              <div class="custom-upload-ff">
                <div *ngIf="!userProjectPreviewImage">
                  <input type="file" id="file-input" class="file-input" (change)="onChange($event)" accept="image/*" />
                  <label for="file-input" class="upload-label">
                    <div class="upload-icon">
                      <img src="assets/images/img/cloud_upload.png" />
                    </div>

                    <span class="upload-text">Upload Cover</span>
                  </label>
                </div>
                <!-- <div *ngIf="userProjectPreviewImage">
                  <img [src]="userProjectPreviewImage" alt="">
                </div> -->
                <div class="img-uploaded" *ngIf="userProjectPreviewImage">
                  <img [src]="userProjectPreviewImage" class="" alt="" />
                  <!-- <img [src]="userProjectPreviewImage" class="img-project" alt=""> -->
                  <div class="img-btns-upload">
                    <div class="btn btn-grad-ff" (click)="onRemoveRecordPhoto()">Remove</div>
                    <!-- <div class="btn btn-grad-ff" (click)="onChange($event)">Change</div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-8 mb-2">
              <div class="mb-3">
                <h6 class="title6-highlighted">Project Name:</h6>
                <input type="text" class="input-field form-control" formControlName="string1" />
              </div>
              <small class="text-danger" *ngIf="addForm.get('string1')?.hasError('required') && addForm.get('string1')?.touched">
                Project Name is required.
              </small>

              <div class="row mb-1">
                <div class="col-md-6 mb-2">
                  <h6 class="title6-highlighted">From :</h6>
                  <input type="month" placeholder="yyyy-mm" class="input-field form-control " formControlName="date1"
                    (input)="handleInput($event, 'date1')" />
                </div>
                <div class="col-md-6 mb-2">
                  <h6 class="title6-highlighted">To :</h6>
                  <input type="month" placeholder="yyyy-mm" class="input-field form-control" (input)="handleInput($event, 'date2')"
                    formControlName="date2" />
                </div>
                <small
  class="text-danger"
  *ngIf="
    (addForm.get('date1')?.touched && addForm.get('date1')?.hasError('invalidProjectDate')) ||
    (addForm.get('date2')?.touched && addForm.get('date2')?.hasError('invalidProjectDate'))
  "
>
  Enter valid dates in yyyy-mm format.
</small>

              </div>
              <div class="mb-3">
                <h6 class="title6-highlighted">Role:</h6>
                <select class="input-field form-control" aria-label="Default select example" formControlName="string2">
                  <option value="" disabled selected>Select your option</option>
                  <option *ngFor="let role of roleLists" [value]="role">
                    {{ role }}
                  </option>
                </select>
              </div>
              <div class="mb-3">
                <h6 class="title6-highlighted">Link to Project(optional)</h6>
                <input type="text" class="input-field form-control" formControlName="string4" />
              </div>

                <small
                class="text-danger"
                *ngIf="addForm.get('string4')?.touched && addForm.get('string4')?.hasError('invalidHttps')"
              >
                The link must start with "https://".
              </small>
          
              <div class="mb-1">
                <h6 class="title6-highlighted">Details:</h6>
                <textarea name="" class="input-field form-control" id="" formControlName="text1"></textarea>
              </div>
            </div>
          </div>
          <div class="row mb-1">
            <div class="col-md-12">
              <div class="text-end">
                <div 
                class="mb-1 btn btn-grad-ff ms-2" 
                [ngClass]="{'disabled': !addForm.valid}" 
                (click)="onEditModalSubmit('project', '#projectEdit')" 
                [class.disabled]="!addForm.valid">
                <span>Save</span>
              </div>

                <div class="mb-1 btn btn-secondary-outline ms-2" (click)="onModalCancel('#projectEdit')">
                  <span>Cancel</span>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="achievementForm" tabindex="-1" aria-labelledby="exampleModalLabel">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-app-primary">
      <div class="modal-body p-4">
        <form [formGroup]="addForm">
          <div class="mb-3">
            <h6 class="title6-highlighted">Award Name</h6>
            <input type="text" class="input-field form-control border" formControlName="string1" />
          </div>
          <div class="mb-3">
            <h6 class="title6-highlighted">Awarded For</h6>
            <input type="text" class="input-field form-control border" formControlName="string2" />
          </div>
          <div class="mb-3">
            <h6 class="title6-highlighted">Date</h6>
            <input type="month" class="input-field form-control border" (input)="handleInput($event, 'date1')"
              formControlName="date1" />
          </div>
          <div class="mb-1 text-end">
            <div class="btn btn-grad-ff ms-2" (click)="onEditModalSubmit('achievement', '#achievementForm')">
              <span>Save </span>
            </div>
            <div class="btn btn-secondary-outline ms-2" (click)="onModalCancel('#achievementForm')">
              <span> Cancel </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="networkForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-app-primary">
      <div class="modal-body p-4">
        <form [formGroup]="addForm">
          <div class="mb-3">
            <h6 class="title6-highlighted">Network</h6>
            <input type="text" class="input-field form-control border" formControlName="string1" />
          </div>
          <div class="mb-1 text-end">
            <div class="btn btn-grad-ff ms-2 mt-1" (click)="onEditModalSubmit('network', '#networkForm')">
              <span>Save </span>
            </div>
            <div class="btn btn-secondary-outline ms-2 mt-1" (click)="onModalCancel('#networkForm')">
              <span>Cancel </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="recommendationForm" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-app-primary">
      <div class="modal-body p-4">
        <form [formGroup]="addForm">
          <div class="mb-3">
            <div class="text-white">Feedback</div>
            <textarea class="input-field form-control" formControlName="text1"></textarea>
          </div>
          <div class="mb-1 text-end">
            <div class="btn btn-grad-ff ms-2" (click)="recommendationAdd()">
              <span>Save </span>
            </div>
            <div class="btn btn-secondary-outline ms-2" (click)="onModalCancel('#recommendationForm')">
              <span>Cancel </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="deleteModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content bg-app-primary">
      <div class="modal-body p-4">
        <div class="mb-3">
          <div class="title6-highlighted">
            Do you want to delete this {{ currentEditCourse?.name }}?
          </div>
        </div>
        <div class="mb-1 text-end">
          <div class="btn btn-secondary-outline ms-2" (click)="closeModal('#deleteModal')">
            <span>Discard </span>
          </div>
          <div class="btn btn-grad-ff ms-2" (click)="onDeleteClickV2()">
            <span>Ok</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>