import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

@Component({
  selector: 'app-project-watch',
  templateUrl: './project-watch-create.component.html',
  styleUrls: ['./project-watch-create.component.scss'],
})
export class ProjectWatchCreateComponent {
  watchListForm!: FormGroup;
  videoId: any

  constructor(
    private formBuilder: FormBuilder,
    private apiHelper: ApiHelper,
    private router: Router,
  ) {
    this.watchListForm = this.formBuilder.group({
      name: ['', Validators.required],
      description: ['', Validators.required],
      genre: ['', Validators.required],
      language: ['', Validators.required],
      mediaLinks: ['', Validators.required],
      linkRestrict: ['', Validators.required],
    });
    this.watchListForm.get('mediaLinks')?.valueChanges.subscribe((value) => {
      this.extractVideoId(value);
    });
  }

  ngOnInit() {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }
  extractVideoId(url: string): string | null {
  const regex =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?.*)?$/;
    const match = url.match(regex);
    return this.videoId = match ? match[1] : null;
  }

  onSubmit() {
    if (this.watchListForm.valid) {
      const videoId = this.extractVideoId(this.watchListForm.value.mediaLinks);
      this.watchListForm.patchValue({ mediaLinks: videoId });
    }
    console.log('data' ,this.watchListForm.value )
    this.apiHelper
      .post(this.watchListForm.value, ApiEndPoints.watchListCreate)
      .subscribe((response) => {
         response.data;
      });
    this.router.navigate(['/view/watchNow/list'], {
      onSameUrlNavigation: 'reload'
    }).then(() => {
      window.location.reload();
    });
  }
}
