import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare global {
  interface Window {
    dataLayer: any[];
    gtag: (...args: any[]) => void;
  }
}
@Injectable({
  providedIn: 'root'
})
export class GoogleTagService {
  constructor(private router: Router) {

    this.initializeGTM();

    this.setupPageViewTracking();
  }


  initializeGTM(){
    const script = document.createElement('script');
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleTagManager}`;
      document.head.appendChild(script);

      window.dataLayer = window.dataLayer || [];
      window.gtag = function(...args: any[]) {
        window.dataLayer.push(arguments);
      }
      window.gtag('js', new Date());
      window.gtag('config', environment.googleTagManager,{send_page_view: false});
  }


  setupPageViewTracking(){
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.trackPageView(event.urlAfterRedirects);
    });
  }
  trackPageView(path: string):void {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', 'page_view', {
        page_path: path,
        page_location: window.location.href,
        page_title: document.title
      });
    }
  }
  trackEvent(eventName: string,
    eventCategory: string,
    eventLabel: string = '',
    eventValue: number 
  ): void {
    if (typeof window.gtag !== 'undefined') {
      window.gtag('event', eventName, {
        event_category: eventCategory,
        event_label: eventLabel,
        value: eventValue
      });
    }
  }

}