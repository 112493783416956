import { Component } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ApiEndPoints } from 'src/app/core/constants';
import { ApiHelper } from 'src/app/core/service/api.helper';

declare var $: any;

@Component({
  selector: 'app-project-watch-view',
  templateUrl: './project-watch-view.component.html',
  styleUrls: ['./project-watch-view.component.scss'],
})
export class ProjectWatchViewComponent {
  watchNowData: any;
  activeVideo: string | null = null;
  id: any;
  memberDetails: any;
  isProjectAdmin: boolean = false;
  visibility: boolean = false;
  watchNowEditForm!: FormGroup;

  constructor(
    private apihelper: ApiHelper,
    private activateRoute: ActivatedRoute,
    private formBuilder: FormBuilder
  ) {
    this.watchNowEditForm = this.formBuilder.group({
      id: [''],
      name: ['', Validators.required],
      description: ['', Validators.required],
      genre: ['', Validators.required],
      language: ['', Validators.required],
      mediaLinks: ['', Validators.required],
      linkRestrict: ['', Validators.required],
    });
  }

  ngOnInit() {
    this.id = this.activateRoute.snapshot.paramMap.get('id');
    this.getWatchList(this.id);
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    document.body.appendChild(tag);
  }

  getWatchList(id: string) {
    this.apihelper
      .post({ id: id }, ApiEndPoints.watchListGet)
      .subscribe((response) => {
        this.watchNowData = response.data.watchNowDetails;
        this.memberDetails = response.data.memberDetails;
        if (
          response.data.userLoggingActivity.userActiveMembership.some(
            (obj: any) => obj.role === 'admin'
          )
        ) {
          this.isProjectAdmin = true;
          this.visibility = true;
        }
      });
  }
  onPlayerReady() {}
  onStateChange(event: any) {
    this.activeVideo = event;
  }

  onProjectDetailsEdit() {
    this.watchNowEditForm.patchValue(this.watchNowData);

    ($('#watchNowEditForm') as any).modal('show');
  }

  extractVideoId(url: string): string | null {
    const regex =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|embed\/|v\/|shorts\/)|youtu\.be\/)([a-zA-Z0-9_-]{11})(?:\?.*)?$/;
    const match = url.match(regex);
    return match ? match[1] : null;
  }

  onEditSubmit() {
    if (!this.watchNowEditForm.valid) {
      return;
    }

    if (this.watchNowEditForm.get('mediaLinks')?.dirty) {
      const videoId = this.extractVideoId(
        this.watchNowEditForm.value.mediaLinks
      );
      this.watchNowEditForm.patchValue({ mediaLinks: videoId });

    }
    this.watchNowEditForm.patchValue({ id: this.id });
    this.apihelper
      .post(this.watchNowEditForm.value, ApiEndPoints.watchListUpdate)
      .subscribe((response) => {
        this.watchNowData = response.data;
        this.onModalCancel();
      });
  }

  onModalCancel() {
    this.watchNowEditForm.reset({});
    ($('#watchNowEditForm') as any).modal('hide');
  }
}
